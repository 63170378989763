import { manifestMeta } from 'theme/meta/base';
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { relRobotsHandler } from 'theme/meta/utils/robots';
import { RobotsEnum } from './types'
import { htmlDecode } from '@vue-storefront/core/filters';
import config from 'config';
import store from '@vue-storefront/core/store'
import i18n from '@vue-storefront/i18n'
import { getThumbnailPath } from '@vue-storefront/core/helpers';

const getPriceFilter = () => {
  let priceSelected = []
  const route = store.getters['url/getCurrentRoute']
  if (route.query.price !== undefined) {
    if (Array.isArray(route.query.price)) {
      priceSelected = route.query.price[0].split('-')
    } else {
      priceSelected = route.query.price.split('-')
    }
  }
  return `${i18n.t('Price')}, грн ${priceSelected[0]}-${priceSelected[1]}`
}

const getFilters = (route, category) => {
  const searchQuery = store.getters['varus-cafe/getCurrentSearchQuery'](route, category)
  const result = []

  if (searchQuery.filters !== undefined) {
    for (const key of Object.keys(searchQuery.filters)) {
      if (Array.isArray(searchQuery.filters[key])) {
        searchQuery.filters[key].forEach(e => {
          result.push({ filterType: key, filterData: e })
        })
      }
    }
  }

  if (route.query.has_promotion_in_stores !== undefined) {
    result.push({ filterType: 'has_promotion_in_stores' })
  }

  if (route.query.sort !== undefined) {
    result.push({ filterType: 'sort', sortType: route.query.sort })
  }
  return result
}

const getSortMetaData = (sortType = '', filtersLength: number, currentCategory = null, slug) => {
  const sortMetaData = { h1: '', title: '' }

  if (filtersLength > 1 || (filtersLength === 1 && slug.length > 0)) {
    switch (sortType) {
      case 'final_price:asc': sortMetaData.h1 = String(i18n.t('cheaper')); break
      case 'final_price:desc': sortMetaData.h1 = String(i18n.t('expensive')); break
      case 'created_at:desc': sortMetaData.h1 = String(i18n.t('Novelties')).toLowerCase(); break
      default: sortMetaData.h1 = ''
    }
  } else {
    switch (sortType) {
      case 'final_price:asc': sortMetaData.h1 = currentCategory.name + ' (' + i18n.t('cheaper') + ')'; break
      case 'final_price:desc': sortMetaData.h1 = currentCategory.name + ' (' + i18n.t('expensive') + ')'; break
      case 'created_at:desc': sortMetaData.h1 = i18n.t('Novelties') + ': ' + currentCategory.name; break
      default: sortMetaData.h1 = currentCategory.name
    }
  }

  sortMetaData.title = sortMetaData.h1 + ' | VARUS'
  return sortMetaData
}

const getH1List = (filters = [], currentCategory = null, slug = '') => {
  const attributes = store.getters['attribute/getAttributeListByCode']
  let list = ''

  filters.forEach((e, index) => {
    const separator = index ? '; ' : ''
    switch (e.filterType) {
      case 'sort': {
        const sortH1 = getSortMetaData(e.sortType, filters.length, currentCategory, slug)?.h1
        if (sortH1.length) list += separator + sortH1;
        break
      }
      case 'has_promotion_in_stores':
        list += separator + i18n.t('Promotion on') + ' ' + currentCategory?.name.toLowerCase()
        break
      case 'price':
        const priceFilter = getPriceFilter()
        list += separator + (priceFilter)
        break
      // all other types of filters
      default:
        if (attributes[e.filterType] !== undefined) {
          const filterLabel = attributes[e.filterType]?.frontend_label
          const filterValue = e.filterData.label
          if (list.includes(filterLabel + ' -')) {
            list += ', ' + filterValue
          } else {
            list += separator + filterLabel + ' - ' + filterValue
          }
        }
    }
  })

  // Add slugs to the h1 list
  if (slug.length > 0) {
    const separator = filters.length ? '; ' : ''
    list += separator + store.getters['category/getCategoryBySlug'](slug).name
  }

  return list
}

export function getMetaContent (category = null, route = {}, slug = '', humanFilterMeta) {
  const currentCategory = category || {}
  const attributes = store.getters['attribute/getAttributeListByCode']

  let h1 = htmlDecode(currentCategory?.meta_h1 || '')
  let additional = i18n.t('META_ADDITIONAL_TITLE') as string
  let description = htmlDecode(currentCategory?.meta_description)
  const filters = getFilters(route, currentCategory) || []

  // no filters
  if (!filters.length) {
    h1 = h1 || currentCategory?.name
  }

  // no filters but have slug
  if (filters.length === 0 && slug.length > 0) {
    const categoriesName = store.getters['category/getCategoryBySlug'](slug).name
    h1 = currentCategory.name + ': ' + categoriesName
  }

  // only one filter
  if (filters.length === 1 && slug.length === 0) {
    const firstFilter = filters[0]
    switch (firstFilter.filterType) {
      case 'pim_brand_id':
        h1 = h1 || (currentCategory?.name + ' ' + firstFilter.filterData.label)
        additional = i18n.t('META_ADDITIONAL_TITLE_BRANDS') as string

        break
      case 'sort':
        const sortMetaData = getSortMetaData(firstFilter.sortType, filters.length, currentCategory, slug)
        h1 = h1 || sortMetaData.h1
        break
      case 'has_promotion_in_stores':
        h1 = h1 || (i18n.t('Promotion on') + ' ' + currentCategory?.name.toLowerCase())
        break
      case 'price':
        const priceFilter = getPriceFilter()
        h1 = h1 || (currentCategory?.name + ': ' + priceFilter)
        break
      // all other types of filters
      default:
        h1 = h1 || (currentCategory?.name + ': ' + attributes[firstFilter.filterType]?.frontend_label + ' - ' + firstFilter.filterData.label)
    }
  }

  // multiple filters
  if (filters.length > 1 || (filters.length === 1 && slug.length > 0)) {
    h1 = currentCategory.name + ': ' + getH1List(filters, currentCategory, slug)
  }

  const title = `${h1} ${additional}`
  description = description || h1 + ' ' + i18n.t('META_DESCRIPTION')

  return {
    h1: humanFilterMeta?.meta_h1 || h1,
    title: humanFilterMeta?.meta_title || title,
    description: humanFilterMeta?.meta_description || description,
    filters
  }
}

export function metaCategory () {
  const currentCategory = this.getVarusCafeCategory
  const route = this.$route
  const slug = route.params?.slug ? route.params.slug : ''
  const i18nStore = config.storeViews?.[this.$store.state.storeView.storeCode]?.i18n
  const currentLocale = i18nStore?.defaultLocale;
  const baseUrl = this.$store.getters['siteData/baseUrl'];
  const url = `${baseUrl}/${currentCategory?.url_path}`;
  const manifest = manifestMeta(this.$store);
  const getBannersTop = this.$store.getters['promoted/getBannersTop'];
  const getProductPromotionBanners = this.$store.getters['promoted/getProductPromotionBanners'];
  const banners = [...getBannersTop, ...getProductPromotionBanners]
  const firstBanner = banners[0];
  let relRobots;

  if (route?.params.page || route?.query.page) {
    relRobots = relRobotsHandler(RobotsEnum.NOINDEX, config.xEnv.key)
  }

  if (route.query.sort) {
    relRobots = relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key);
  }

  const metaContent = getMetaContent(currentCategory, route, slug, this.humanFilterMeta)

  const commonCount: any = Object.keys(this.currentFilters).reduce((a, c) => {
    const length = this.currentFilters[c].length
    a[c] = length
    a._commonLength += length

    return a
  }, {
    _commonLength: 0
  })

  const isBrandFilter = (commonCount._commonLength === 1 && commonCount?.pim_brand_id === 1)

  if (!isBrandFilter && commonCount._commonLength >= 1) {
    relRobots = relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key);
  }

  if (this.humanFilterMeta?.index && this.humanFilterMeta?.follow) {
    relRobots = relRobotsHandler(
      `${this.humanFilterMeta?.index},${this.humanFilterMeta?.follow}`,
      config.xEnv.key
    );
  }

  const isRelRobots = relRobots || {}

  const meta = {
    title: metaContent.title,
    meta: {
      ...isRelRobots,
      title: {
        name: 'title',
        content: metaContent.title
      },
      description: {
        name: 'description',
        content: metaContent.description
      },
      ogType: {
        property: 'og:type',
        content: 'article'
      },
      ogTitle: {
        name: 'og:title',
        content: metaContent.title
      },
      ogDescription: {
        name: 'og:description',
        content: metaContent.description
      },
      ogUrl: {
        name: 'og:url',
        content: url
      },
      ogLocale: {
        name: 'og:locale',
        content: currentLocale
      }
    },
    link: [
      // temporarily hidden according to task VAR-3141
      // {
      //   rel: 'canonical',
      //   href: url
      // },
      {
        rel: 'preload',
        href: getThumbnailPath(firstBanner?.image_mobile) || getThumbnailPath(firstBanner?.image_desktop) || '',
        as: 'image'
      },
      ...manifest.link
    ]
  };

  if (this.humanFilterMeta?.meta_keywords) {
    meta.meta.keywords = {
      name: 'keywords',
      content: this.humanFilterMeta.meta_keywords
    }
  }

  return mergeMeta(meta)
}
